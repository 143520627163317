exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artworks-figures-in-a-landscape-js": () => import("./../../../src/pages/artworks-figures-in-a-landscape.js" /* webpackChunkName: "component---src-pages-artworks-figures-in-a-landscape-js" */),
  "component---src-pages-artworks-miniature-distant-landscapes-js": () => import("./../../../src/pages/artworks-miniature-distant-landscapes.js" /* webpackChunkName: "component---src-pages-artworks-miniature-distant-landscapes-js" */),
  "component---src-pages-artworks-projected-heritage-js": () => import("./../../../src/pages/artworks-projected-heritage.js" /* webpackChunkName: "component---src-pages-artworks-projected-heritage-js" */),
  "component---src-pages-artworks-surface-js": () => import("./../../../src/pages/artworks-surface.js" /* webpackChunkName: "component---src-pages-artworks-surface-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-curation-100-geographies-js": () => import("./../../../src/pages/curation-100geographies.js" /* webpackChunkName: "component---src-pages-curation-100-geographies-js" */),
  "component---src-pages-curation-centenary-university-js": () => import("./../../../src/pages/curation-centenary-university.js" /* webpackChunkName: "component---src-pages-curation-centenary-university-js" */),
  "component---src-pages-curation-landbouweekblad-js": () => import("./../../../src/pages/curation-landbouweekblad.js" /* webpackChunkName: "component---src-pages-curation-landbouweekblad-js" */),
  "component---src-pages-curation-remnants-js": () => import("./../../../src/pages/curation-remnants.js" /* webpackChunkName: "component---src-pages-curation-remnants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

