import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { persistor, store } from "./src/state/createStore"

// import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
// import { loadStripe } from "@stripe/stripe-js"

// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx")

export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}
