import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

const INITIAL_STATE = {
  shoppingcart: [],
  firebase: null
}

const verbose = false

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === "UPDATE_CART") {
    if (verbose) console.log("updating cart, huzzah")
    return Object.assign({}, state, { shoppingcart: action.shoppingcart, firebase: state.firebase })
  }
  if (action.type === "SET_FIREBASE") {
    if (verbose) console.log("updating fb, huzzah")
    if (verbose) console.log(Object.keys(action.firebase))
    return Object.assign({}, state, { firebase: action.firebase, shoppingcart: state.shoppingcart })
  }

  return state
}
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["navigation", "firebase"],
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer)

const persistor = persistStore(store)
export { persistor, store }
